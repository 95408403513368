@mixin blink-animation-delay {
  @for $i from 1 through 17 {
    &:nth-child(17n + #{$i}) {
      .circle {
        animation-delay: -#{$i * 23}s;
      }
    }
  }
  @for $i from 1 through 13 {
    &:nth-child(13n + #{$i}) {
      .circle {
        animation-duration: #{abs(3.25-$i/6)}s;
      }
    }
  }
}

.container {
  height: 1030vw;
  width: 100%;
  position: fixed;

  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-template-columns: repeat(4, 25vw);
  grid-template-rows: repeat(40, 25vw);
  background: black;
  position: relative;

  .column {
  }
}
