body {
  overflow: hidden;
}

.container {
  position: absolute;

  display: grid;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: #030505;

  grid-template-columns: repeat(20, 5vw);
  grid-template-rows: repeat(100, 5vw);
  overflow-y: scroll;

  .comp {
    width: 5vw;
    height: 5vw;
    position: relative;

    .circle {
      position: absolute;
      border-radius: 50%;
    }
  }
}
