body {
  overflow: hidden;
}

.whole {
  background: #030505;
  position: fixed;
  width: 100%;
  height: 100%;
}

.container {
  position: fixed;
  display: flex;

  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;

  .comp {
    position: relative;

    .square {
      border-radius: 50%;
      position: absolute;
    }
  }
}
