body {
  overflow: hidden;
}

.whole {
  background: #030505;
  position: fixed;
  width: 100%;
  height: 100%;
}

.container {
  position: fixed;
  display: grid;

  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  grid-template-columns: repeat(40, 2.5vw);
  grid-template-rows: repeat(200, 2.5vw);

  .comp {
    position: relative;

    .square {
      position: absolute;
      border-radius: 50%;
    }
  }
}
