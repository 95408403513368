.container {
  width: 100vw;
  height: 100vh;

  background: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: all 0.3s;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appearSeven {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}

.inputWrapper {
  width: calc(min(80vw, 500px));
  height: calc(min(80vw, 500px) * 0.5);
  border: dashed 3px #bbb;
  border-radius: 0.8rem;
  position: relative;

  transition: all 0.3s;

  animation: appear 0.5s;
}

.imageWrapper {
  width: calc(min(100vw, 500px));
  height: calc(min(100vw, 500px) * 0.7);
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.3s;

  img {
    animation: appear 1s backwards;
    animation-delay: 1s;
    max-width: 100%;
    max-height: 100%;

    object-fit: contain;

    transition: all 0.3s;
  }
}

.header {
  text-align: center;
  margin: 1rem 0;

  transition: all 0.3s;
  color: #ddd;
}

.instruction {
  text-align: center;
  max-width: 80%;
  margin: 1rem 0;
  p {
    margin: 0.5rem;
  }

  transition: all 0.3s;
  color: #bbb;

  animation: appear 0.5s backwards;
  animation-delay: 0.5s;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  div {
    animation: appearSeven 0.5s backwards;
    animation-delay: 0.5s;
    font-size: 1.2rem;
    margin: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    border: 1px solid white;
    cursor: pointer;
    opacity: 0.7;
    transition: all 0.3s;
  }

  div:last-child {
    animation: appear 0.5s backwards;
    animation-delay: 0.6s;
    opacity: 1;
    font-weight: bold;
    border: 2px solid white;
  }
}
.imageInput {
  cursor: pointer;
  outline: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  opacity: 0;
}

.text {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;

  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;

  pointer-events: none;

  span {
    padding: 0;
    margin: 0;
    animation: flip-text-rotate 9s infinite linear;
    pointer-events: none;
  }

  @keyframes flip-text-rotate {
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(360deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
}
