@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  width: 100vw;
  height: 100vh;

  background: black;
  top: 0;
  left: 0;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: 1.6rem;
    margin: 0;
  }
  h3 {
    font-size: 1.6rem;
    margin: 0.5rem;
  }
  h1 {
    font-size: 2rem;
    margin: 0.8rem;

    animation: appear 2s backwards;
    animation-delay: 1s;

    &:last-child {
      animation-delay: 3s;
    }
  }

  .imageWrapper {
    width: 30vw;
    height: 30vw;
    margin: 4rem;

    img {
      width: 100%;
      height: 100%;
    }

    animation: appear 2s backwards;
    animation-delay: 2s;
  }
}
