@mixin blink-animation-delay {
  @for $i from 1 through 17 {
    &:nth-child(17n + #{$i}) {
      animation-delay: -#{random(160) + 20}s;
    }
  }
}

.container1 {
  height: 100%;
  width: 100%;
  position: absolute;

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(100, 31px);
  grid-template-rows: repeat(100, 31px);

  .square1 {
    height: 31px;
    width: 31px;
  }
}

.container2 {
  height: 100%;
  width: 100%;
  position: absolute;
  background: black;

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(20, 5vw);
  grid-template-rows: repeat(80, 5vw);

  z-index: -2;

  .square2 {
    height: 5vw;
    width: 5vw;
  }
}

@keyframes movement {
  to {
    transform: translate(10vw, 5vh);
  }
}

@keyframes blink {
  to {
    opacity: 0;
  }
}

@keyframes appearance-change {
  to {
    transform: scale(0.7);
  }
}
