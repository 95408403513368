.container {
  position: absolute;
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: black;
  flex-direction: column;
  overflow: hidden;

  .number {
    font-size: 8rem;
    font-weight: 700;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
