.container {
  background: black;
  color: white;
  height: 100vh;

  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  overflow-x: hidden;
  text-decoration: none;
  padding-bottom: 2rem;

  .mainHeader {
    margin: 2rem 3rem;
    display: flex;

    .title {
      font-size: 2.5rem;
      font-weight: bold;
    }
    .mainText {
      margin-left: 2rem;
      font-size: 0.9rem;
      margin-top: 0.5rem;

      .mainTextRow {
      }
    }
  }

  .rowContainer {
    margin-top: 1rem;
    margin-left: 3rem;
    .keyInfo {
      display: flex;

      .header {
        font-size: 1.5rem;
        font-weight: bold;
      }
      .year {
        font-size: 1rem;
        margin-left: 1.4rem;
        margin-top: 0.5rem;
      }
    }

    .listContainer {
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      margin: 1.5rem 0;
      margin-right: 3rem;
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }
      & ::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }

      .elContainer {
        min-width: 15rem;
        height: 15rem;
        position: relative;

        .pic {
          width: 15rem;
          height: 15rem;
          position: absolute;
          margin: auto;
          object-fit: cover;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }

        .hoverViewer {
          position: absolute;
          top: 0;
          left: 0;
          width: 15rem;
          height: 15rem;

          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          cursor: pointer;

          background: rgba(0, 0, 0, 0.4);
          font-size: 0.8rem;
          opacity: 0;
          transition: opacity 0.5s;

          &:hover {
            opacity: 1;
          }

          .title {
            margin: 0.5rem;
          }
          .link {
            margin: 0.5rem;
            font-weight: bold;
          }
        }
      }
    }
  }

  .footer {
    cursor: pointer;
    font-size: 0.8rem;
    margin: 2rem 3rem;
  }
}

@media (max-width: 768px) {
  .mainHeader {
    margin: 5rem 2rem !important;
    flex-direction: column !important;
    .title {
      font-size: 2rem !important;
    }
    .mainText {
      margin-left: 0 !important;
      margin-top: 1rem !important;
    }
  }

  .rowContainer {
    margin-left: 2rem !important;
    .listContainer {
      margin-right: 2rem !important;
    }
  }

  .footer {
    margin-left: 2rem !important;
  }
}
