.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100vw;
  min-height: 100vh;
  overflow-y: scroll;
  background-color: black;

  color: white;
}

.header {
  width: 20.3rem;
  text-align: left;
  margin-top: 1rem;
  h3 {
    margin: 0.3rem;
  }

  h3:nth-child(2) {
    margin-bottom: 1rem;
  }

  p {
    margin: 0.1rem;
    color: #bbb;
    font-size: 0.9rem;
  }
}

.button {
  margin: 1.5rem 0;
  width: 100vw;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  border-top: 1px solid white;
  border-bottom: 1px solid white;
  font-size: 1rem;
  padding: 0.4rem 0;

  img {
    position: absolute;
    left: calc(50vw - 10.15rem);
    height: 1.4rem;
    width: auto;
  }
}

.indicator {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 0.7rem 0;
}

.table {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  max-height: 40vh;
  overflow-y: scroll;
  .row {
    margin: 0.8rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    color: #bbb;

    &:first-child {
      font-size: 0.9rem;
      padding-bottom: 0.7rem;
      border-bottom: 1px solid white;
      margin-bottom: 0.2rem;
    }
  }

  .nickname {
    width: 9rem;
    font-size: 1rem;
  }
  .expected {
    font-size: 0.8rem;
    width: 6rem;
  }

  .left {
    font-size: 0.8rem;
    width: 5.3rem;
  }
}
