.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  overflow-y: scroll;
  background-color: black;

  color: white;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header {
  animation: appear 1s;
  text-align: left;

  width: 26rem;

  h2 {
    font-weight: normal;
    font-size: 1rem;
    margin: 0.5rem 0;
  }

  h1 {
    margin: 0.5rem 0;
  }

  p {
    margin: 0.1rem;
    color: #bbb;
    font-size: 0.9rem;
  }
  margin-bottom: 2rem;
}

.indicator {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
}

.table {
  animation: appear 1s;
  margin: 1.5rem 0;
  font-size: 1.2rem;
  font-weight: bold;
  .row {
    margin: 0.8rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:first-child {
      font-size: 0.9rem;
      padding-bottom: 0.7rem;
      border-bottom: 1px solid white;
      margin-bottom: 0.6rem;
    }
  }

  .nickname {
    width: 10rem;
  }
  .expected {
    font-size: 1.2rem;
    width: 8rem;
  }

  .left {
    font-size: 1.2rem;
    width: 8rem;
  }
}

.qrSector {
  animation: appear 1s;
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
  .imageWrapper {
    width: 20vw;
    height: 20vw;
    margin: 3rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  h2 {
    font-weight: normal;
  }

  h1 {
    margin: 0.5rem;
  }
}
