@mixin blink-animation-delay {
  @for $i from 1 through 17 {
    &:nth-child(17n + #{$i}) {
      animation-delay: -#{random(160) + 20}s;
    }
  }
}

.containerOne {
  height: 100vh;
  width: 100vw;
  position: absolute;

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(100, 31px);
  grid-template-rows: repeat(100, 31px);

  .squareOne {
    height: 31px;
    width: 31px;
  }
}

.containerTwo {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background: black;

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(20, 5vw);
  grid-template-rows: repeat(80, 5vw);

  z-index: -2;

  .squareTwo {
    height: 5vw;
    width: 5vw;
  }
}

.containerThree {
  height: 100%;
  width: 100vw;
  position: absolute;
  background: black;

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(20, 131px);
  grid-template-rows: repeat(80, 131px);

  z-index: -4;

  .squareThree {
    opacity: 1;
    height: 131px;
    width: 131px;
  }
}

@keyframes movement {
  to {
    transform: translate(10vw, 5vh);
  }
}

@keyframes blink {
  to {
    opacity: 0;
  }
}

@keyframes appearance-change {
  to {
    transform: scale(0.7);
  }
}
