.container {
  background: black;
  color: white;
  height: 100vh;
  width: 100%;
  position: fixed;

  display: flex;
  flex-direction: row;

  overflow-y: scroll;
  text-decoration: none;
}

.sector {
  margin: 20px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;

  .title {
    margin-top: 20px;
    font-size: 2rem;
    text-transform: uppercase;
  }

  .childContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    .child {
      margin: 5px;
      text-transform: capitalize;
      color: #aaa;

      &:hover {
        // text-transform: uppercase;
        color: white;
        cursor: pointer;
      }
    }
  }
}

.footer {
  position: fixed;
  font-size: 2rem;
  width: 100vw;
  background: black;
  margin-top: 1vh;
  height: 10vh;
  font-weight: bold;
  color: white;
  bottom: 0;
  margin: auto;
  text-align: center;
  left: 0;
  right: 0;
}
