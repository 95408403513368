.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.shitga {
  width: calc(min(70vw, 30vh));
  height: calc(min(70vw, 30vh));
  margin: 0.5rem;

  animation: appear 0.7s backwards;
  animation-delay: 0.3s;
}

.description {
  margin: 0.5rem;
  text-align: center;

  h1 {
    margin: 0.5rem;
  }

  p {
    margin: 0rem;
  }

  animation: appear 0.7s backwards;
  animation-delay: 0.5s;
}

.audioList {
  width: 100vw;
  margin: 1rem 0;

  .audio {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    width: 100vw;

    border-top: 1px solid white;
    font-size: 1rem;
    padding: 0.4rem 0;

    img {
      position: absolute;
      left: 2rem;
      height: 1.4rem;
      width: auto;
    }

    &:last-child {
      border-bottom: 1px solid white;
    }

    animation: appear 0.7s backwards;
  }
}

.caption {
  margin-top: 1rem;
  max-width: 80%;
  text-align: center;
  font-size: 0.8rem;

  animation: appear 0.7s backwards;
  animation-delay: 1.5s;
}
