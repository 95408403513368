@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.background {
  backdrop-filter: blur(2px);
  width: 100vw;
  height: 100vh;
  background: rgba(200, 200, 200, 0.3);
  position: fixed;
  top: 0;
  left: 0;

  animation: appear 0.5s;

  transition: all 0.3s;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 3;
  width: calc(min(80vw, 500px));
  padding: 1rem;
  height: 13rem;
  background: black;
  color: white;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: all 0.3s;
}

.header {
  text-align: center;
  transition: all 0.3s;
  margin: 0.5rem;
  h3 {
    margin: 0.5rem 0;
    color: #ddd;
  }

  p {
    margin: 0.2rem 0;
    color: #bbb;
  }
}

.inputWrapper {
  width: 10rem;
  height: 2rem;
  margin: 0;
  padding: 0;
  margin-top: 1rem;

  transition: all 0.3s;

  .input {
    color: white;
    margin: 0;
    padding: 0;
    outline: 0;
    width: 10rem;
    height: 100%;
    text-align: center;

    border-radius: 0;

    text-size-adjust: auto;
    border: none;
    background: transparent;
    font-size: 1.2rem;
    font-weight: bold;

    transition: all 0.3s;
    border-bottom: 1px solid white;
    &::placeholder {
      border-radius: 0;

      color: #aaa;
      opacity: 0.5;
      padding-bottom: 0.3rem;
      font-size: 1.2rem;
    }
  }
}

.submitButton {
  animation: appear 0.5s backwards;

  font-size: 1.2rem;
  margin: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: 1px solid white;
  cursor: pointer;
}
