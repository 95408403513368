.container {
  position: absolute;

  display: flex;
  width: 100vw;

  background: black;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;

  .number {
    font-size: 0.1rem;
    font-weight: 700;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    .single {
      width: 0.001rem;
      text-align: right;

      &:nth-of-type(3) {
        text-align: center;
      }
      &:nth-of-type(5) {
        text-align: left;
      }
    }

    .divider {
      width: 0.03rem;
      margin-top: -0.05rem;
    }
  }
}
