@mixin rotate-animation-delay {
  @for $i from 1 through 17 {
    &:nth-child(17n + #{$i}) {
      animation-delay: -#{random(160) + 20}s;
    }
  }
  @for $i from 1 through 31 {
    &:nth-child(31n + #{$i}) {
      animation-duration: #{random(40) + 30}s;
    }
  }
  @for $i from 1 through 2 {
    &:nth-child(2n + 1) {
      animation-direction: reverse;
    }
  }
}

.Boxcontainer {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;

  animation: rotate 40s linear infinite;
  @include rotate-animation-delay;

  &:last-child {
    z-index: -5;
    background: black;
    animation: none;
  }

  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
