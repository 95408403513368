$color1: black;
$color2: rgb(4, 39, 43);

@mixin blink-animation-delay {
  @for $i from 1 through 10 {
    &:nth-child(40n + #{2 * $i - 1}) {
      animation: breathe-y-plus 1.2s linear forwards;
      animation-delay: 1.3s;
    }
    &:nth-child(40n + #{2 * $i}) {
      animation: breathe-x-plus 1.2s linear forwards;
      animation-delay: 1.2s;
    }
    &:nth-child(40n + #{2 * $i + 19}) {
      animation: breathe-x-minus 1.2s linear forwards;
      animation-delay: 1.4s;
    }
    &:nth-child(40n + #{2 * $i + 20}) {
      animation: breathe-y-minus 1.2s linear forwards;
      animation-delay: 1.5s;
    }
  }
}

.container {
  height: 100%;
  width: 100%;
  position: fixed;

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(40, 100px);
  grid-template-rows: repeat(40, 100px);
  background: $color1;

  .comp {
  }

  .compinit {
    animation: opacity 0.8s forwards;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-bottom: 90px solid $color2;
    position: relative;
    top: -50px;

    &:after {
      content: "";
      position: absolute;
      left: -50px;
      top: 50px;
      width: 0;
      height: 0;
      border: 50px solid transparent;
      border-top: 90px solid $color2;
    }
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
