.container {
  position: absolute;

  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: black;
  flex-direction: column;

  .result {
    font-size: 70px;
    width: 320px;
    max-width: 320px;
    text-align: right;
    margin-right: 15px;
    margin-bottom: 20px;
    color: white;
    font-weight: 700;
  }

  .calculator {
    display: grid;
    grid-template-columns: repeat(4, 80px);

    .square {
      height: 60px;
      width: 60px;
      margin: 10px;

      background: rgb(247, 246, 236);
      box-shadow: inset 0 0 20px #777, 0 0 10px white;
      display: flex;
      align-items: center;
      justify-content: center;

      text-align: center;
      font-size: 25px;
      font-weight: 700;
      text-shadow: 0 0 7px white;
      cursor: pointer;
    }
  }
}
