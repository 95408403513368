@mixin blink-animation-delay {
  @for $i from 1 through 17 {
    &:nth-child(17n + #{$i}) {
      .circle {
        animation-delay: -#{$i * 23}s;
      }
    }
  }
  @for $i from 1 through 13 {
    &:nth-child(13n + #{$i}) {
      .circle {
        animation-duration: #{abs(4.25-$i/6)}s;
      }
    }
  }
}

.container {
  height: 160vw;
  width: 100%;
  position: fixed;

  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow-x: hidden;

  grid-template-columns: repeat(8, 10vw);
  grid-template-rows: repeat(20, 10vw);
  background: black;
  position: relative;

  .box {
    position: relative;
    width: 25vw;
    height: 25vw;
    margin-top: -7.5vw;
    margin-left: -7.5vw;
    @include blink-animation-delay;

    .circle {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 18vw;
      height: 18vw;
      border-radius: 50%;
      color: white;

      animation: rotatee 5s infinite linear;

      span {
        position: absolute;
        transform-origin: bottom center;
        height: 9.4vw;
        user-select: none;
        -webkit-user-select: none;
        -webkit-user-drag: none;
      }
    }
  }
}

@keyframes rotatee {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
