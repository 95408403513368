@mixin rotate-animation-delay {
  @for $i from 1 through 17 {
    &:nth-child(17n + #{$i}) {
      animation-delay: -#{random(160) + 20}s;
    }
  }
  @for $i from 1 through 31 {
    &:nth-child(31n + #{$i}) {
      animation-duration: #{random(40) + 30}s;
    }
  }
  @for $i from 1 through 2 {
    &:nth-child(2n + 1) {
      animation-direction: reverse;
    }
  }
}

.whole {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -5;
  background: black;
  animation: none;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;

  .ellipse {
    border-radius: 30% 70% 26% 74% / 30% 30% 70% 70%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}
