.container {
  width: 100%;
  height: 100%;
  background: black;
  position: fixed;
  color: white;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  .quote {
    quotes: "";
    font-size: 2rem;
    animation: appear 3s forwards;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
