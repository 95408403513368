.background {
  width: 100vw;
  height: 100vh;
  background: black;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  min-height: 90vh;
  overflow-y: scroll;
  background-color: black;
  color: white;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.introduction {
  margin-top: 1rem;
  text-align: center;
  max-width: 90%;
  h1 {
    font-size: 2.5rem;
    margin: 0.5rem;
  }
  p {
    font-size: 0.8rem;
    animation: appear 0.7s backwards;
    animation-delay: 0.4s;
  }
}

.shitga {
  width: calc(min(70vw, 30vh));
  height: calc(min(70vw, 30vh));
  margin: 0.3rem;
  animation: appear 0.7s backwards;
  animation-delay: 0.8s;
}

.description {
  margin: 0.5rem;
  text-align: center;

  animation: appear 0.7s backwards;
  animation-delay: 0.8s;

  h1 {
    margin: 0.5rem;
  }

  p {
    margin: 0;
    font-size: 0.8rem;
  }
}

.navigatorList {
  width: 100vw;
  margin-top: 3rem;
  margin: 1rem 0;
  margin-bottom: 4rem;

  text-align: center;
  .navigator {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    width: 100vw;

    border-top: 1px solid white;
    font-size: 1rem;
    padding: 0.4rem 0;

    animation: appear 0.7s backwards;

    img {
      position: absolute;
      left: 2rem;
      height: 1.4rem;
      width: auto;
    }

    &:last-child {
      border-bottom: 1px solid white;
    }
  }
}
