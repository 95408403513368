body {
  overflow: hidden;
}

.container {
  position: absolute;

  display: grid;
  width: 100vw;
  height: 400vh;
  align-items: center;
  justify-content: center;
  background: #030505;

  grid-template-columns: repeat(10, 10vw);
  grid-template-rows: repeat(400, 10vw);
  overflow-y: scroll;

  .comp {
    width: 10.3vw;
    height: 10.3vw;
  }

  .compclicked {
    width: 10.3vw;
    height: 10.3vw;
  }

  .block {
    display: grid;
    align-items: center;
    justify-content: center;
    position: absolute;
  }
}
