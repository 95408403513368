@mixin blink-animation-delay {
  @for $i from 1 through 17 {
    &:nth-child(17n + #{$i}) {
      animation-delay: -#{random(20) / 5}s;
      font-weight: #{$i}* 100;
    }
  }
}

:root {
  --c1: #6ba782;
  --c2: #cbedb5;
  --c3: #679b69;
  --c4: #bfe6c1;
}

.container {
  height: 100%;
  width: 100%;
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: var(--c4);

  .light {
    width: 100%;
    height: 5px;

    animation: move 7s linear infinite;
  }

  .light:nth-child(1) {
    background: var(--c1);
    animation: move 3s linear infinite;
  }

  .light:nth-child(2) {
    background: var(--c2);
  }

  .light:nth-child(3) {
    background: var(--c3);
  }
}

@keyframes move {
  0% {
    top: -100px;
  }
  100% {
    top: 100vw;
  }
}
