@mixin rotate-animation-delay {
  @for $i from 1 through 17 {
    &:nth-child(17n + #{$i}) {
      animation-delay: -#{random(160) + 20}s;
    }
  }
  @for $i from 1 through 31 {
    &:nth-child(31n + #{$i}) {
      animation-duration: #{random(40) + 30}s;
    }
  }
  @for $i from 1 through 2 {
    &:nth-child(2n + 1) {
      animation-direction: reverse;
    }
  }
}

.whole {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -5;
  background: black;
  animation: none;
}

.Boxcontainer {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;

  grid-template-columns: repeat(100, 1vw);
  grid-template-rows: repeat(600, 1vw);

  animation: rotate 40s linear infinite;
  @include rotate-animation-delay;

  .box {
    width: 100%;
    height: 100%;
  }
}
