@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  width: 100vw;
  height: 100vh;
  background: black;

  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;

  h1 {
    animation: appear 2s backwards;
  }

  .expl {
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
    flex-direction: column;
    h1 {
      animation: appear 2s backwards;
    }
  }
}

.wrapper {
  width: 100vw;
  height: 100vh;
  background: black;

  top: 0;
  left: 0;

  animation: appear 0.5s;
}
