.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;

  text-align: center;
}

.upper {
  margin-top: 4rem;
}
.header {
  margin: 1rem;
  text-align: center;

  h2 {
    font-size: 1rem;
  }
}

.displayable {
  width: 100vw;
  height: 30vh;
}

.button {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  border: 5px double #ccc;
  // box-shadow: 0 0 10px white;
  margin: 0.5rem;
  cursor: pointer;
}

.navigatorList {
  width: 100vw;
  margin: 1.5rem 0;
  margin-bottom: 7rem;

  text-align: center;

  .navigator {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    width: 100vw;

    border-top: 1px solid white;
    font-size: 1rem;
    padding: 0.4rem 0;

    img {
      position: absolute;
      left: 2rem;
      height: 1.4rem;
      width: auto;
    }
  }
}
