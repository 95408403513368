.container {
  height: 100%;
  width: 100vw;
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;

  background: hsl(200, 100%, 50%);

  .square {
    position: absolute;
    transition: 2s;
  }
}
