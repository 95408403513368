.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s;

  .left {
    width: 1.5rem;
    height: auto;
    margin-left: 1rem;
    margin-right: 1.5rem;
    cursor: pointer;
    transition: all 0.3s;
  }

  .main {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .right {
    font-size: 0.8rem;
    width: 4rem;
    text-align: center;
  }
}
