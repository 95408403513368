.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
  text-align: center;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pic {
  width: 50vw;
  height: 50vh;
  margin: 1rem;
  margin-top: 10vh;

  animation: appear 2s backwards;
  animation-delay: 0.5s;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.upper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  animation: appear 2s backwards;
  animation-delay: 1s;

  h1 {
    font-size: 6vh;
    margin: 0.5rem;
    font-weight: 500;
  }
  h2 {
    font-size: 5vh;
    margin: 0;
    padding: 0;
    font-weight: 500;
  }
}
