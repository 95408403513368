.display {
  text-align: center;
  margin: 1rem;
  margin-bottom: 1rem;

  font-size: 10vh;
  font-weight: bold;

  transition: all 0.3s;
}
