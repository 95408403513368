.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  background-color: black;
  color: white;
  overflow-y: scroll;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.audios {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    padding: 0.5rem 0.8rem;
    border-radius: 0.3rem;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 1rem;
      width: auto;
      margin-right: 0.5rem;
    }
  }
}

.link {
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  border: 1px solid white;

  animation: appear 0.7s backwards;
  animation-delay: 0.5s;
  text-align: center;

  margin-bottom: 10vh;
}

.audioPlayer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  animation: appear 0.7s backwards;
  animation-delay: 1s;
}

.scriptWrapper {
  max-width: 80vw;
  max-height: 50vh;

  overflow-y: scroll;
  margin-top: 0.5rem;

  animation: appear 0.7s backwards;
  animation-delay: 1.5s;

  .header {
    text-align: center;
    font-size: 1.6rem;
    margin: 2rem 0;
    font-weight: bold;
  }

  .script {
    div {
      margin: 0.7rem 0;
    }
  }
}
