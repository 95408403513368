.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 800px;
  height: 800px;

  .number {
    font-size: 8rem;
    font-weight: 700;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
