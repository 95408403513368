.container {
  height: 100%;
  width: 100%;
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgb(15, 27, 20);

  .square {
    position: absolute;
  }
}
