.container {
  width: 100%;
  height: 100%;
  background: black;

  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.wrapper {
  width: 100%;
  height: 100%;
  background: black;

  top: 0;
  left: 0;
}
