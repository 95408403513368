@mixin animation-adjust {
  @for $i from 1 through 31 {
    &:nth-child(31n + #{$i}) {
      .square {
        animation-delay: -#{$i}s;
      }
    }
  }

  @for $i from 1 through 23 {
    &:nth-child(23n + #{$i}) {
      .square {
        animation-duration: #{$i * 0.5+15}s;
      }
    }
  }
}

body {
  overflow: hidden;
}

.whole {
  background: #030505;
  position: fixed;
  width: 100%;
  height: 100%;
}

.container {
  position: fixed;
  display: flex;

  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .comp {
    position: relative;

    .square {
      position: absolute;
      border-radius: 50%;
    }
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes opaque {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 0.1;
  }
}
