.container {
  height: 100vh;
  width: 100vw;
  position: fixed;

  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-template-columns: repeat(4, 25vw);
  grid-template-rows: repeat(25, 25vw);
  background: black;

  .set {
    position: relative;
    width: 25vw;
    height: 25vw;

    .column {
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }
}
