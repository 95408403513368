$color1: black;
$color2: rgb(4, 39, 43);

@mixin blink-animation-delay {
  @for $i from 1 through 31 {
    &:nth-child(31n + #{$i}) {
      .circleTwo {
        animation-delay: -#{random(10)}s;
      }
    }
  }
  @for $i from 1 through 13 {
    &:nth-child(13n + #{$i}) {
      .circleTwo {
        animation-duration: -#{random(14) + 12}s;
      }
    }
  }
}

.containerTwo {
  height: 100%;
  width: 100%;
  position: fixed;
  background: black;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(20, 10vw);
  grid-template-rows: repeat(80, 10vw);

  z-index: -2;

  .boxTwo {
    position: relative;
    @include blink-animation-delay;
    .circleTwo {
      height: 10vw;
      width: 10vw;
      border-radius: 50%;
      box-shadow: inset 0 0 3vw #aaa, 0 0 3vw #888, 1vw 1vw 2vw #aaa;
      animation: changes 5s infinite alternate;
    }
  }
}

@keyframes changes {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
