@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.background {
  backdrop-filter: blur(2px);
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;

  animation: appear 0.5s;
}

.modalContainer {
  z-index: 0;
  width: calc(min(80vw, 500px));
  height: calc(min(70vh, 600px));

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  background: #aaa;
  border-radius: 1rem;

  overflow: hidden;

  color: black;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  animation: appear 0.5s;

  .header {
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
  }

  .description {
    height: 80%;
    margin: 0rem 1.5rem;
    overflow-y: scroll;
    text-align: left;

    p {
      margin: 1.4rem 0;
    }
  }

  .cancelButton {
    padding: 0.1px;
    position: absolute;
    right: 0;

    top: 0;

    width: 2rem;
    height: 2rem;

    border-radius: 50%;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 1000;

    img {
      width: 70%;
      height: 70%;
    }
  }
}
