.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  position: relative;
  overflow: auto;

  .adjustable {
    width: 50vw;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    resize: both;
  }
}
