.mapboxgl-control-container {
  opacity: 0;
}

.marker {
  transform: translate(-50%, -50%);
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(66, 74, 255, 1) 20.83%,
    rgba(8, 30, 63, 0) 100%
  );
  /* backdrop-filter: hue-rotate(100deg); */
  /* backdrop-filter: brightness(400%); */
  /* backdrop-filter: invert(100%); */
}
