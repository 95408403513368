.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
  font-size: 2rem;

  .header {
    position: absolute;
    top: 5vh;
    left: 0;
    right: 0;
    margin: auto;
    h1 {
      margin: 0;
      padding: 0;
    }
    text-align: center;
  }

  .footer {
    position: absolute;
    bottom: 5vh;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 1rem;
    text-align: center;
  }

  .singleSeries {
    position: absolute;

    bottom: 0;
    top: 0;
    margin: auto;

    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2rem;

    div {
      font-size: 2rem;

      span {
        font-size: 2rem;
      }
      @keyframes appear {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      animation: appear 0.3s backwards;
    }
  }
}
