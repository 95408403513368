.wrapper {
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  // background: linear-gradient(rgb(133, 6, 82), rgb(11, 123, 208));
}
